import React from 'react';
import { Link } from 'react-router-dom';
import ribbon from "../../assets/header_byt.png";
import right_ribbon from "../../assets/header_treack.png"
import { useTranslation } from "react-i18next";
import { useCart } from '../../context/ CartContext';


function Header() {
  const { t } = useTranslation();
  const { cart } = useCart();
  const totalItems = cart.reduce((total, item) => total + item.quantity, 0);

  return (
    <header>
      <div className="navbar-header">
        <div className="navbar-ribbons">
          <img src={ribbon} alt="" />
        </div>

        <div className="navbar-order">
          <Link to="/cart">{t('view_your_order')}: {totalItems}</Link>
        </div>

        <div className="navbar-ribbons">
          <img src={right_ribbon} alt="" />
        </div>
      </div>
    </header>
  );
}

export default Header;
