import React, { useEffect, useRef, useState } from "react";
import Layout from "../../utils/layout";
import { Button, Spinner, Modal } from "react-bootstrap";
import defaultimg from "../../assets/logotran.png";
import "./style.css";
import '../../components/modal/style.css'
import { realtimeDb } from "../../firebase/firebase";
import { ref, get, query, orderByChild, equalTo } from "firebase/database";
import { useLocation } from "react-router-dom";
import { useCart } from "../../context/ CartContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function IceCream() {
  const cartRef = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  let { pathname } = location;
  if (pathname.startsWith("/")) {
    pathname = pathname.substring(1).toUpperCase();
  }
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const { cart, setCart } = useCart();
  const [showAlert, setShowAlert] = useState(false);
  const [show, setShow] = useState({ show: false, confirmModal: false });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [descp, setDescp] = useState(false);


  const handleClose = () => setShow({ ...show, show: false, confirmModal: false });
  const handleShow = (product) => {
    console.log(product, "modal data");
    setSelectedProduct(product);
    setShow({ ...show, show: true });
    setDescp(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const snapshot = await get(
          query(
            ref(realtimeDb, "products"),
            orderByChild("category"),
            equalTo(pathname)
          )
        );
        if (snapshot.exists()) {
          const dataArray = Object.values(snapshot.val()).filter((a)=> a.show === true);
          // console.log("Data array:", dataArray.filter((a)=> a.show === true));
          setData(dataArray);
           // Filter data by subcategoryV2 only if product.show is true
           const filteredData = dataArray?.reduce((acc, product) => {
              const subcategory = product.subcategoryV2?.value || " ";
              if (!acc[subcategory]) {
                acc[subcategory] = [];
              }
              acc[subcategory].push(product);
            return acc;
          }, {});
          console.log(filteredData,'filterdta');
          setFilteredData(filteredData);
        }else {
          console.log("No data available");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [pathname]);

  const handleAdd_withmodal = (product) => {
    const updatedCart = [...cart];
    const foundProduct = updatedCart.find((item) => item.key === product.key);

    if (foundProduct) {
      foundProduct.quantity += 1;
      foundProduct.price = foundProduct.basePrice * foundProduct.quantity;
    } else {
      updatedCart.push({
        ...product,
        quantity: 1,
        basePrice: parseFloat(product.price),
      });
    }

    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setShowAlert(true);
    toast.success(t('item_success_msg'));
    if (descp) {
      setShow({ ...show, show: false, confirmModal: true });
    }

    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const handleAddToCart = (e, product) => {
    console.log(e, product, 'test>>>>>>>>>>>');
    const updatedCart = [...cart];
    const foundProduct = updatedCart.find((item) => item.key === product.key);
    console.log(updatedCart,'foundproduct6666');
    if (foundProduct) {
      foundProduct.quantity += 1;
      foundProduct.price = foundProduct.basePrice * foundProduct.quantity;
    } else {
      updatedCart.push({
        ...product,
        quantity: 1,
        basePrice: parseFloat(product.price),
      });
    }
    console.log(updatedCart,'updatedCart>>>>>>>>>>>')
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setShowAlert(true);
    toast.success(t('item_success_msg'));
    if (descp) {
      setShow({ ...show, show: false, confirmModal: false });
    }
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
    const button = e.currentTarget;
    const itemElement = button.closest(".product-items");
    const img = itemElement.querySelector("img");

    // Ensure cartRef.current is initialized and accessible
    if (img && cartRef.current) {
      const imgClone = img.cloneNode(true);
      const imgRect = img.getBoundingClientRect();
      const cartRect = cartRef.current.getBoundingClientRect();

      imgClone.style.position = "absolute";
      imgClone.style.top = `${imgRect.top + 200}px`;
      imgClone.style.left = `${imgRect.left + 200}px`;
      imgClone.style.opacity = "1";
      imgClone.style.height = "100px";
      imgClone.style.width = "100px";
      imgClone.style.borderRadius = "50%";
      imgClone.style.borderWidth = "1px";
      imgClone.style.borderStyle = "solid";
      imgClone.style.borderColor = "black";
      imgClone.style.zIndex = "100";
      document.body.appendChild(imgClone);

      requestAnimationFrame(() => {
        imgClone.style.transition = "all 1s ease-in-out";
        imgClone.style.top = `${cartRect.top + 10}px`;
        imgClone.style.left = `${cartRect.left + 10}px`;
        imgClone.style.height = "50px";
        imgClone.style.width = "50px";

        setTimeout(() => {
          cartRef?.current?.classList?.add("shake");
          setTimeout(() => cartRef?.current?.classList?.remove("shake"), 100);
          imgClone.style.top = `${cartRect.top + 30}px`;
          imgClone.style.left = `${cartRect.left + 30}px`;
          imgClone.style.width = "0";
          imgClone.style.height = "0";
          setTimeout(() => imgClone.remove(), 1000);
        }, 1000);
      });
    }
  };

  const [loaded, setLoaded] = useState(false);

  return (
    <Layout>
      <div className="product-page">
        <div className="shopping-cart" ref={cartRef}>
        </div>
        <h4>{t("ice_creams")}</h4>
        {Object.keys(filteredData).map((subcategory,index) => (
          <div key={subcategory}>
          {index > 0 &&  <hr />}
            <h5 className="text-muted mb-3">{subcategory}</h5>
            <div className="product-grid">
              {filteredData[subcategory].map((item) => (
                <div key={item.id} className="product-items">
                  <div className="product-cover"
                    key={item.id}
                    onClick={() => handleShow(item)}>
                    {!loaded && <img src={defaultimg} alt="Default" />}
                    {loaded && <img src={item.image} alt="Loaded" />}
                    <img
                      src={item?.image}
                      alt={item.name}
                      style={{ display: "none" }}
                      onLoad={() => setLoaded(true)}
                    />
                    <span>{item.price}</span>
                  </div>
                  <div className="product-title">
                    <p
                      key={item.id}
                      onClick={() => handleShow(item)}>{item.name}</p>
                    <Button onClick={(e) => handleAddToCart(e, item)}>+</Button>
                  </div>

                </div>
              ))}
            </div>
          </div>
        ))}
     <Modal
          show={show.show}
          onHide={handleClose}
          centered
          className="modal-product"
          size="lg"
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="modal-grid">
              <div className="product-info">
                <h2>{selectedProduct?.name}</h2>
                <div className="description">
                  <h3>{t("description")}</h3>
                  <p>
                    <p>{selectedProduct?.description}</p>
                  </p>
                </div>
                <div className="description">
                  <h3>{t("ingredient")}</h3>
                  <p>{selectedProduct?.ingredient}</p>
                </div>
                <div>
                  <button
                    className="add-button"
                    onClick={() => handleAdd_withmodal(selectedProduct)}
                  >
                    {t("add")}
                  </button>
                </div>
              </div>
              <div className="popup_image">
                {!loaded && (
                  <img
                    src={defaultimg}
                    alt="Alexander Special"
                    className="modal-image"
                  />
                )}
                {loaded && (
                  <img
                    src={selectedProduct?.image}
                    alt="Alexander Special"
                    className="modal-image"
                  />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={show.confirmModal}
          onHide={handleClose}
          centered
          className="modal-product"
          size="lg"
        >
          <Modal.Body>
            <div className="modal-grid">
              <div className="product-info">
                <h2>{t("good_choice")}</h2>
                <div className="description">
                  <p>{t("good_choice_msg")}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" className="modal-footer-btn" onClick={handleClose}>{t("ok")}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Layout>
  );
}

export default IceCream;
