import React from "react";
import "./style.css";
import {   useNavigate } from "react-router-dom";
import {   Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import netslogo from "../assets/nets_logo.png";



function PaymentFailed() {
  const navigate = useNavigate();
  const { t } = useTranslation();
 

 

  return (
    <div className=" payment-container">
      <div className="container-wrapper shadow p-5">
          <div className="form-container">
            <h3  className="text-danger" >
            {t("swish_payment_failed")}
            </h3>
          </div>

        <div className="image-container">
         <img src={netslogo} alt="Nets Logo" className="swish-logo" />
          </div>
          <div className="image-container ">
          {/* <p>{t('open_swishapp_accept')}</p> */}
         
            
          </div>
          <div className="image-container ">
          {/* <Spinner
              style={{
                color: "pink",
                width:50,
                height:50
              }}
            /> */}
            
          </div>

          <div className="button-container">
            <button
              className="cancel-button"
              onClick={() => {
                navigate("/cart")
              }}
            >
              {t("swish_payment_failed_button")}
             
            </button>
          </div>
        </div>

      <div></div>
      <Toaster/>
    </div>
  );
}

export default PaymentFailed;
