import Header from "../components/header";
import Sidebar from "../components/sidebar";

function Layout({ children }) {
    return (
      
      <div className="layout-wrapper">

        <Header />

        <Sidebar />

        <div className="main-content">
            <div className="page-content">
                {children}
            </div>
        </div>

       
      </div>
    );
  }
  
  export default Layout;
  