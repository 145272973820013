import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-white.png";
import icecream from "../../assets/ice-cream.png";
import drinks from "../../assets/drinks.png";
import bg from "../../assets/bg-2.jpg";
import "./style.css";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();
    return (
        <div className="home">
          <div className="home-cover">
            <img src={bg} alt="" />
          </div>
          <div className="home-content">
            <div className="container">
              <div className="home-grid">

                <div className="home-link">
                  <Link to="/ice_cream">
                    <img src={icecream} alt="" />
                    <span>{t('ice_creams')}</span>
                  </Link>
                </div>

                <div className="home-logo">
                  <Link to="/"><img src={logo} alt="" /></Link>
                </div>

                <div className="home-link">
                  <Link to="/drinks">
                    <img src={drinks} alt="" />
                    <span>{t('drinks')}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
  
export default Home;
  