import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "swe",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        welcome: "Please select your language",
        ok: "OK",
        view_your_order: "View Your Order",
        ice_creams: "Ice Creams",
        drinks: "Drinks",
        survey:"Survey",
        amount_in_cart: "Amount in cart",
        quantity: "Quantity",
        name: "Name",
        type: "Type",
        amount: "Amount",
        action: "Action",
        enter_table_code: "Table Number",
        click_here: "Click here",
        pay_easy_with_cards: "Pay easily with cards",
        pay_desc:
          "Pay easily and quickly with cards. Requires access to bank ID. (Non-Swedish customers do NOT need a bank ID.)",
        call_staff_and_paycash: "Call staff and pay in cash",
        call_staff_desc: "Note that waiting time may occur",
        swish_payment: "Swish payment",
        swish_pay_desc: "Only for Swedish users",
        please_enter_table_no: "Please enter guest order code.",
        enter_password: "Enter your password",
        total_amount:" Total Amount",
        order_desc:" Our waiter will come to you in few minutes.",
        edit_order:"EDIT ORDER",
        done_staff:"DONE(STAFF REQUIRED)",
        enter_payment_password:"Enter payment password",
        cancel:"CANCEL",
        done:"DONE",
        item_success_msg:"Item added successfully!",
        description:"Description",
        ingredient:"Ingredient",
        add:'Add',
        good_choice:"Good Choice!",
        good_choice_msg:"This item is now added to your cart",
        survey_msg:" Thanks for your payment, now your order is sent to the kitchen where your ice cream dishes are prepared.",
        survey_title:"While waiting, please answer these two short questions",
        yes_please:"YES PLEASE",
        no_thanks:"NO THANKS",
        quastion:"Question",
        all_question_completed:"All questions completed!",
        pay_amount:"Your payment amount is",
        enter_mobile_no:"Enter Your Mobile Number",
        placeholder_mobileNo:"Enter mobile no",
        swish_payment_msg:"In order to use SWISH you need to be subscribing to the service SWISH via your bank and also have the app Bank-ID available on your phone at the time of the purchase. If not, please cancel and choose other payment options.",
        pay:"Pay",
        cancel_pay:"Cancel",
        mobile_required:"Mobile number is required.",
        payment_success:"Payment successfully",
        payment_faild:"Payment Failed.",
        thank_u:"Thank You!",
        canceled:"Canceled",
        dont_cancel_swish:"NOTE! Do not cancel or close this window, confirmation of SWISH payment can take up to 2 minutes.",
        open_swishapp_accept:"Please open your SWISH app to accept payment.",
        swish_payment_successfully :"Thanks for your payment, now your order is sent to the kitchen where your ice cream dishes are prepared.\nWhile waiting, please answer these two short questions.",
        swish_payment_failed :"Something went wrong with your payment, Please try another payment method or see the staff.",
        swish_payment_failed_button :"Go Back",
        

        
      },
    },
    de: {
      translation: {
        welcome: "Bitte wählen Sie Ihre Sprache",
        ok: "OK",
        view_your_order: "Sehen Sie sich Ihre Bestellung an",
        ice_creams: "Eis",
        drinks: "Getränke",
        survey:"Umfrage",
        amount_in_cart: "Menge im Warenkorb",
        quantity: "Menge",
        name: "Name",
        type: "Typ",
        amount: "Menge",
        action: "Aktion",
        enter_table_code: "Tisch Nummer",
        click_here: "klicken Sie hier",
        pay_easy_with_cards: "Bezahlen Sie bequem mit Karten",
        pay_desc:
          "Bezahlen Sie einfach und schnell mit Karten. Erfordert Zugriff auf die Bank-ID. (Nicht-schwedische Kunden benötigen KEINEN Bankausweis.)",
        call_staff_and_paycash:
          "Rufen Sie das Personal an und zahlen Sie in bar",
        call_staff_desc: "Note that waiting time may occur",
        swish_payment: "Bitte beachten Sie, dass es zu Wartezeiten kommen kann",
        swish_pay_desc: "Nur für schwedische Benutzer",
        please_enter_table_no: "Bitte Gast-Bestellcode eingeben",
        enter_password: "Geben Sie Ihr Passwort ein",
        total_amount:"Gesamtmenge",
        order_desc:"Unser Kellner wird in wenigen Minuten zu Ihnen kommen",
        edit_order:"BESTELLUNG BEARBEITEN",
        done_staff:"FERTIG (PERSONAL ERFORDERLICH)",
        enter_payment_password:"Zahlungspasswort eingeben“",
        cancel:"CANCSTORNIERENEL",
        done:"ERLEDIGT",
        item_success_msg:"Artikel erfolgreich hinzugefügt!",
        description:"Zutat",
        ingredient:"Ingrediens",
        add:'Hinzufügen',
        good_choice:"Gute Wahl!",
        good_choice_msg:"Dieser Artikel wurde nun Ihrem Warenkorb hinzugefügt",
        survey_msg:"Vielen Dank für Ihre Zahlung, jetzt wird Ihre Bestellung an die Küche gesendet, wo Ihre Eisgerichte zubereitet werden.",
        survey_title:"Während Sie warten, beantworten Sie bitte diese beiden kurzen Fragen",
        yes_please:"JA, BITTE",
        no_thanks:"NEIN DANKE",
        quastion:"Frage",
        all_question_completed:"Alle Fragen beantwortet!",
        pay_amount:"Ihr Zahlungsbetrag beträgt",
        enter_mobile_no:"Geben Sie Ihre Mobiltelefonnummer ein",
        placeholder_mobileNo:"Geben Sie die Handy-Nr. ein",
        swish_payment_msg:"Um SWISH nutzen zu können, müssen Sie den Dienst SWISH über Ihre Bank abonnieren und zum Zeitpunkt des Kaufs außerdem über die Bank-ID der App auf Ihrem Telefon verfügen. Wenn nicht, stornieren Sie bitte und wählen Sie andere Zahlungsoptionen.",
        pay:"Zahlen",
        cancel_pay:"Stornieren",
        mobile_required:"Handynummer ist erforderlich.",
        payment_success:"Zahlung erfolgreich",
        payment_faild:"Bezahlung fehlgeschlagen.",
        thank_u:"Danke schön!",
        canceled:"Abgesagt",
         dont_cancel_swish:"HINWEIS! Brechen Sie dieses Fenster nicht ab oder schließen Sie es nicht. Die Bestätigung der SWISH-Zahlung kann bis zu 2 Minuten dauern.",
        open_swishapp_accept:"Bitte öffnen Sie Ihre SWISH-App, um die Zahlung zu akzeptieren.",
        swish_payment_successfully:"Vielen Dank für Ihre Zahlung. Jetzt wird Ihre Bestellung an die Küche gesendet, in der Ihre Eisgerichte zubereitet werden.\nBeantworten Sie während des Wartens bitte diese beiden kurzen Fragen.",
        swish_payment_failed :"Bei Ihrer Zahlung ist ein Fehler aufgetreten. Bitte versuchen Sie es mit einer anderen Zahlungsmethode oder wenden Sie sich an die Mitarbeiter.",
        swish_payment_failed_button :"Geh zurück",
        
      },
    },
    swe: {
      translation: {
        welcome: "Välj ditt språk",
        ok: "OK",
        view_your_order: "Se din beställning",
        ice_creams: "Glass",
        drinks: "Drycker",
        survey:"Undersökning",
        amount_in_cart: "Mängd i varukorgen",
        quantity: "Kvantitet",
        name: "namn",
        type: "Typ",
        amount: "Belopp",
        action: "Handling",
        enter_table_code: "Tabellnummer",
        click_here: "Klicka här",
        pay_easy_with_cards: "Betala enkelt med kort",
        pay_desc:
          "Betala enkelt och snabbt med kort. Kräver tillgång till bank-ID. (Icke-svenska kunder behöver INTE bank-ID.)",
        call_staff_and_paycash: "Ring personalen och betala kontant",
        call_staff_desc: "Observera att väntetid kan förekomma",
        swish_payment: "Swish betalning",
        swish_pay_desc: "Endast för svenska användare",
        please_enter_table_no: "Vänligen ange gästorderkod",
        enter_password: "Geben Sie Ihr Passwort ein",
        total_amount:"Totala summan",
        order_desc:"Vår servitör kommer till dig om några minuter.",
        edit_order:"REDIGERA BESTÄLLNING",
        done_staff:"KLAR (PERSONAL KRÄVS)",
        enter_payment_password:"Ange betalningslösenord",
        cancel:"ANNULLERA",
        done:"GJORT",
        item_success_msg:"Objektet har lagts till!",
        description:"Beskrivning",
        ingredient:"Ingrediens",
        add:'Lägg till',
        good_choice:"Bra val!",
        good_choice_msg:"Denna artikel har nu lagts till i din varukorg",
        survey_msg:"Tack för din betalning, nu skickas din beställning till köket där dina glassrätter tillagas.",
        survey_title:"Medan du väntar, vänligen svara på dessa två korta frågor",
        yes_please:"JA TACK",
        no_thanks:"NEJ TACK",
        quastion:"Fråga",
        all_question_completed:"Alla frågor är klara!",
        pay_amount:"Ditt betalningsbelopp är",
        enter_mobile_no:"Skriv in ditt mobilnummer",
        placeholder_mobileNo:"Ange mobilnr",
        swish_payment_msg:"För att använda SWISH behöver du prenumerera på tjänsten SWISH via din bank och även ha appen Bank-ID tillgängligt på din telefon vid köptillfället. Om inte, vänligen avbryt och välj andra betalningsalternativ.",
        pay:"Betala",
        cancel_pay:"Annullera",
        mobile_required:"Mobilnummer krävs.",
        payment_success:"Betalningen lyckades",
        payment_faild:"Betalning misslyckades.",
        thank_u:"Tack!",
        canceled:"Inställt",
        dont_cancel_swish:"OBS! Avbryt inte eller stäng detta fönster, bekräftelse av SWISH-betalning kan ta upp till 2 minuter.",
        open_swishapp_accept:"Vänligen öppna din SWISH-app för att godkänna betalning.",
        swish_payment_successfully:"Tack för er betalning, nu skickas din beställning till köket där era glassrätter tillreds.\nMedan ni väntar, svara gärna på dessa två korta frågor.",
        swish_payment_failed :"Något gick fel med din betalning. Prova en annan betalningsmetod eller kontakta personalen.",
        swish_payment_failed_button :"Gå tillbaka",
      },
    },
  },
});
export default i18n;
