/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button, Table, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import back from "../../assets/back.png";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useCart } from "../../context/ CartContext";
import { realtimeDb } from "../../firebase/firebase";
import {
  equalTo,
  get,
  orderByChild,
  push,
  query,
  ref,
  remove,
  set,
} from "firebase/database";
import { toast } from "react-toastify";

function Cart() {
  const navigate = useNavigate();
  const { cart, setCart } = useCart();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [payment_password, setPayment_password] = useState("");

  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltip1, setShowTooltip1] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [typeArray] = useState([]);
  const [errormsg_tooltip, setErrormsg_tooltip] = useState("");
  // console.log("card", cart);
  // eslint-disable-next-line

  const [cashOrderId, setCashOrderId] = useState(null);
  const [, setUniqueCod] = useState("");
  const [cashOrder] = useState({
    done: false,
    guestName: "",
    tableNumber: 0,
  });
  const [order] = useState({
    compartment: 0,
    drinksCount: 0,
    foodCompartment: 0,
    foodCount: 0,
    foodStatus: "NONE",
    guestName: "",
    guestPhone: "",
    iceCount: 0,
    iceStatus: "",
    items: cart,
    paymentStatus: 0,
    paymentType: "SWISH",
    status: "",
    tableNumber: 0,
    uniqueCode: "",
  });
  console.log(typeArray, "new array");
  const totalAmount = cart
    .reduce((total, item) => {
      const itemPrice = parseFloat(item.price);
      if (!isNaN(itemPrice)) {
        return total + itemPrice;
      }
      return total;
    }, 0)
    .toFixed(2);

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
      console.log(JSON.parse(savedCart), "savedCart>>>>>>>>>");
    }
  }, [setCart]);

  //   const handleRemoveItem = (productId) => {
  //     const updatedCart = cart.filter(item => item.id !== productId);
  //     setCart(updatedCart);
  //     localStorage.setItem("cart", JSON.stringify(updatedCart));
  //   };

 

  const checkFirstOrder = async () => {
    const uniqueCodeRef = await get(query(ref(realtimeDb, "OrderExtraInfo")));
    if (uniqueCodeRef.exists()) {
      const uniqueCode = uniqueCodeRef.val();
      console.log(uniqueCode, "uniqueCode");
      const nextDay = uniqueCode?.nextDay;
      const startOfDay = uniqueCode?.startOfDay;
      const date = new Date(nextDay.split("-").reverse().join("-"));
      date.setHours(startOfDay.split(":")[0]);
      date.setMinutes(startOfDay.split(":")[1]);
      console.log(date, "date");
      const now = new Date();
      console.log(now, "now");
      console.log(now.getTime(), date.getTime() , now.getTime() > date.getTime());
      if (now.getTime() > date.getTime()) {
        const todayDate = new Date();
        const dd = String(todayDate.getDate() + 1).padStart(2, "0");
        const mm = String(todayDate.getMonth() + 1).padStart(2, "0");
        const yyyy = todayDate.getFullYear();
        const today = `${dd}-${mm}-${yyyy}`;
        console.log("date", today);

        await set(ref(realtimeDb, "OrderExtraInfo"), {
          code: 1,
          nextDay: today,
          startOfDay: uniqueCode?.startOfDay,
        });

        // remove the all previous order
        await set(ref(realtimeDb, "orders"), null);
      }
    }
  };

  const handleRemoveItem = (productId) => {
    console.log(productId, "1111111111");
    const updatedCart = cart
      .map((item) => {
        if (item.key === productId) {
          if (item.quantity > 1) {
            return {
              ...item,
              quantity: item.quantity - 1,
              price: item.basePrice * (item.quantity - 1),
            };
          } else {
            return null;
          }
        }
        return item;
      })
      .filter(Boolean);

    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handleIncreaseQuantity = (productId) => {
    console.log(productId, "000000000000");

    const updatedCart = cart.map((item) => {
      if (item.key === productId) {
        item.quantity += 1;
        item.price = item.basePrice * item.quantity;
      }
      return item;
    });
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const createOrder = async (order) => {
    const items = JSON.parse(localStorage.getItem("cart"));
    const totalQuantities = items?.reduce(
      (accumulator, item) => {
        if (item.category === "DRINK") {
          accumulator.drinks += item.quantity;
        } else if (item.category === "ICE_CREAM") {
          accumulator.iceCreams += item.quantity;
        }
        return accumulator;
      },
      { drinks: 0, iceCreams: 0 }
    );

    console.log("Total Drinks:", totalQuantities.drinks);
    console.log("Total Ice Creams:", totalQuantities.iceCreams, inputValue);

    const querySnapshot = await get(
      query(
        ref(realtimeDb, "reserved-info"),
        orderByChild("table"),
        equalTo(parseInt(inputValue))
      )
    );
    let data;
    if (querySnapshot.exists()) {
      data = querySnapshot.val();
      console.log("iru>>", data);
    } else {
      console.log("Snapshot exists but no matching data found.");
    }
    const { table, guestName, guestNumber } = data[parseInt(inputValue)];
    const now = new Date();
    const time = now.getTime();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const timestamp = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    let order_items = items;
    console.log("order_item", order_items);
    order_items = order_items?.map((item) => ({
      product: {
        ...item,
        price: `${item?.basePrice}`,
      },
      status: "NEW",
      deleted: false,
      count: item?.quantity,
    }));

    console.log("Processed order items", order_items);

    // order_items = order_items?.map((item) => ({
    //   product: item,
    // }));
    // console.log("items", order_items);
    // order_items = order_items?.map((item) => ({
    //   ...item,
    //   status: "NEW",
    //   deleted: false,
    //   count: item?.product?.quantity,
    // }));

    const compartment = await get(query(ref(realtimeDb, "compartment")));
    const iceValue = compartment.val();

    // console.log("ice", iceValue);
    const new_compartment = {
      ...iceValue,
      iceCompartment:
        iceValue?.iceCompartment === 9 ? 1 : iceValue?.iceCompartment + 1,
    };

    const itemRef = ref(realtimeDb, `compartment`);
    await set(itemRef, new_compartment);
    const order_data = {
      ...order,
      items: order_items,
      compartment: iceValue?.iceCompartment,
      iceCount: totalQuantities.iceCreams,
      drinksCount: totalQuantities.drinks,
      tableNumber: table,
      guestName,
      guestPhone: guestNumber,
      time: time,
      timestamp: timestamp,
      status: "NEW",
      iceStatus: totalQuantities?.iceCreams > 0 ? "NEW" : "NONE",
    };

    console.log("order_data", order_data);

    const orderRef = ref(realtimeDb, "orders");
    const newOrderRef = push(orderRef);
    const orderKey = newOrderRef.key;
    const orderDataWithKey = {
      ...order_data,
      key: orderKey,
    };

    await set(newOrderRef, orderDataWithKey);
    return orderKey;
  };

  const createCashOrder = async () => {
    const items = JSON.parse(localStorage.getItem("cart"));
    const totalQuantities = items?.reduce(
      (accumulator, item) => {
        if (item.category === "DRINK") {
          accumulator.drinks += item.quantity;
        } else if (item.category === "ICE_CREAM") {
          accumulator.iceCreams += item.quantity;
        }
        return accumulator;
      },
      { drinks: 0, iceCreams: 0 }
    );

    console.log("Total Drinks:", totalQuantities.drinks);
    console.log("Total Ice Creams:", totalQuantities.iceCreams, inputValue);

    const querySnapshot = await get(
      query(
        ref(realtimeDb, "reserved-info"),
        orderByChild("table"),
        equalTo(parseInt(inputValue))
      )
    );
    let data;
    if (querySnapshot.exists()) {
      data = querySnapshot.val();
      // console.log("iru>>", data);
    } else {
      console.log("Snapshot exists but no matching data found.");
    }
    const { table, guestName } = data[parseInt(inputValue)];
    const order_data = {
      ...cashOrder,
      tableNumber: table,
      guestName,
    };

    // console.log("order_data", order_data);
    const uniqueCodeRef = await get(query(ref(realtimeDb, "OrderExtraInfo")));

    // const codeunique = uniqueCodeRef.val()?.code;
    // setUniqueCod(codeunique);

    let extraInfo = uniqueCodeRef.val();
    setUniqueCod(extraInfo?.code);

    extraInfo = {
      ...extraInfo,
      code: extraInfo?.code + 2,
    };
    const orderExtraRef = ref(realtimeDb, `OrderExtraInfo`);
    await set(orderExtraRef, extraInfo);

    // console.log("uniqueCode", codeunique);

    const orderRef = ref(realtimeDb, "cash");
    const newOrderRef = push(orderRef);
    const orderKey = newOrderRef.key;
    const orderDataWithKey = {
      ...order_data,
      key: orderKey,
    };
    await set(newOrderRef, orderDataWithKey);
    return orderKey;
  };

  const handleCreateOrder = async (order) => {
    const id = await createOrder(order);
    return id;
  };

  const handleCardPayment = async () => {
    const items = JSON.parse(localStorage.getItem("cart"));
    const totalQuantities = items.reduce(
      (accumulator, item) => {
        if (item.category === "DRINK") {
          accumulator.drinks += item.quantity;
        } else if (item.category === "ICE_CREAM") {
          accumulator.iceCreams += item.quantity;
        }
        return accumulator;
      },
      { drinks: 0, iceCreams: 0 }
    );

    console.log("Total Drinks:", totalQuantities.drinks);
    console.log("Total Ice Creams:", totalQuantities.iceCreams, inputValue);

    const querySnapshot = await get(
      query(
        ref(realtimeDb, "reserved-info"),
        orderByChild("table"),
        equalTo(parseInt(inputValue))
      )
    );
    let data;
    if (querySnapshot.exists()) {
      data = querySnapshot.val();
      // console.log("iru>>", data);
    } else {
      console.log("Snapshot exists but no matching data found.");
    }
    const { table, guestName, guestNumber } = data[parseInt(inputValue)];
    const now = new Date();
    const time = now.getTime();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const timestamp = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    let order_items = items;
    // console.log("order_item", order_items);

    order_items = order_items?.map((item) => ({
      product: {
        ...item,
        price: `${item?.basePrice}`,
      },
      status: "NEW",
      deleted: false,
      count: item?.quantity,
    }));

    console.log("Processed order items", order_items);

    const compartment = await get(query(ref(realtimeDb, "compartment")));
    const iceValue = compartment.val();

    // console.log("ice", iceValue);
    const new_compartment = {
      ...iceValue,
      iceCompartment:
        iceValue?.iceCompartment === 9 ? 1 : iceValue?.iceCompartment + 1,
    };

    const itemRef = ref(realtimeDb, `compartment`);
    await set(itemRef, new_compartment);
    const order_data = {
      ...order,
      items: order_items,
      compartment: iceValue?.iceCompartment,
      iceCount: totalQuantities.iceCreams,
      drinksCount: totalQuantities.drinks,
      tableNumber: table,
      guestName,
      guestPhone: guestNumber,
      time: time,
      timestamp: timestamp,
      status: "NEW",
      iceStatus: totalQuantities?.iceCreams > 0 ? "NEW" : "NONE",
      paymentStatus: 0,
      paymentType: "BY_CARD",
    };

    // console.log("order_data", order_data);

    const orderRef = ref(realtimeDb, "orders");
    const newOrderRef = push(orderRef);
    const orderKey = newOrderRef.key;
    const orderDataWithKey = {
      ...order_data,
      key: orderKey,
    };
    await set(newOrderRef, orderDataWithKey);
    return orderKey;
  };

  const handleButtonClick = async (e, type) => {
    e.preventDefault();
    // console.log("amount", totalAmount);
    if (!totalAmount || parseInt(totalAmount) === 0) {
      toast.warn("At least add one item.", {
        toastId: "failed",
      });
      return;
    }

    if (inputValue.trim() === "") {
      setShowTooltip(true);
      return;
    }
    await checkFirstOrder();
    setShowTooltip(false);
    switch (type) {
      case "with_cards":
        const key = await handleCardPayment();
        console.log("Pay with cards");
        localStorage.setItem("netOrderId", key);
        navigate("/nets", {
          state: {
            id: key,
          },
        });
        break;
      case "swish":
        try {
          const id = await handleCreateOrder(order); // Wait for handleCreateOrder to resolve
          // console.log("id", id);
          localStorage.setItem("order_id", id);
          // console.log("oredr", order);
          // const new_order = JSON.parse(localStorage.getItem("cart"))
          // console.log("new", cart)
          // console.log("new_", new_order)

          let amount = 0;
          amount = cart?.reduce((accumulator, item) => {
            return accumulator + item?.basePrice * item?.quantity;
          }, 0);

          // console.log("am", amount);

          navigate("/swish", {
            state: {
              amount: amount,
              order_id: id,
            },
          });
        } catch (error) {
          console.error("Error creating order:", error);
          // Handle error appropriately, e.g., show error message to user
        }
        break;
      default:
        const id = await createCashOrder(cashOrder);
        setCashOrderId(id);
        setShowModal(true);
        break;
    }
  };

  const handlepaypassword = async (e) => {
    e.preventDefault();
    if (payment_password.trim() === "") {
      setErrormsg_tooltip(t("enter_password"));
      setShowTooltip1(true);
    } else {
      const password = await get(
        query(ref(realtimeDb, "cash_payment_pass_code"))
      );
      let data;
      if (password.exists()) {
        data = password.val();
        // console.log("iru>>>>>>>>>>", data);
      } else {
        // console.log("Snapshot exists but no matching data found.");
      }
      // console.log("paaaaaaaaa>>>>>>>", password, payment_password);
      if (payment_password !== data) {
        setErrormsg_tooltip("Invalid Password");
        setShowTooltip1(true);
      } else {
        const items = JSON.parse(localStorage.getItem("cart"));
        // console.log("cart", items)
        const totalQuantities = items?.reduce(
          (accumulator, item) => {
            if (item?.category === "DRINK") {
              accumulator.drinks += item.quantity;
            } else if (item?.category === "ICE_CREAM") {
              accumulator.iceCreams += item.quantity;
            }
            return accumulator;
          },
          { drinks: 0, iceCreams: 0 }
        );

        // console.log("Total Drinks:", totalQuantities.drinks);
        // console.log("Total Ice Creams:", totalQuantities.iceCreams, inputValue);
        const compartment = await get(query(ref(realtimeDb, "compartment")));
        const iceValue = compartment.val();

        // console.log("iceeeeeeeeee", iceValue);
        const new_compartment = {
          ...iceValue,
          iceCompartment:
            iceValue?.iceCompartment === 9 ? 1 : iceValue?.iceCompartment + 1,
        };

        const itemRef = ref(realtimeDb, `compartment`);
        await set(itemRef, new_compartment);

        const querySnapshot = await get(
          query(
            ref(realtimeDb, "reserved-info"),
            orderByChild("table"),
            equalTo(parseInt(inputValue))
          )
        );
        let data;
        if (querySnapshot.exists()) {
          data = querySnapshot.val();
          // console.log("iru>>>>>>opopo", data);
        } else {
          console.log("Snapshot exists but no matching data found.");
        }
        const { table, guestName, guestNumber } = data[parseInt(inputValue)];

        // Get current time in milliseconds since the Unix epoch

        // const amount = items?.reduce((accumulator, item) => {
        //   return accumulator + item?.price * item?.quantity;
        // }, 0);
        // Format current date and time as "DD-MM-YYYY HH:MM:SS"
        const now = new Date();
        const time = now.getTime();
        const day = String(now.getDate()).padStart(2, "0");
        const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, "0");
        const minutes = String(now.getMinutes()).padStart(2, "0");
        const seconds = String(now.getSeconds()).padStart(2, "0");

        const timestamp = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

        let order_items = items;
        // order_items = order_items?.map((item) => ({
        //   ...item,
        //   price: `${item?.price}`,
        // }));
        // order_items = order_items?.map((item) => ({
        //   product: item,
        // }));
        // order_items = order_items?.map((item) => ({
        //   ...item,
        //   status: "NEW",
        //   deleted: false,
        //   count: item?.product?.quantity,
        // }));
        order_items = order_items?.map((item) => ({
          product: {
            ...item,
            price: `${item?.basePrice}`,
          },
          status: "NEW",
          deleted: false,
          count: item?.quantity,
        }));

        console.log("Processed order items", order_items);

        const order_data = {
          ...order,
          items: order_items,
          iceCount: totalQuantities.iceCreams,
          drinksCount: totalQuantities.drinks,
          tableNumber: table,
          guestName,
          guestPhone: guestNumber,
          paymentType: "CASH",
          time: time,
          timestamp: timestamp,
          iceStatus: totalQuantities.iceCreams ? "NEW" : "NONE",
          // price: amount,
          compartment: iceValue?.iceCompartment || 1,
          // uniqueCode: uniqueCod,
          status: "NEW",
          paymentStatus: 1,
        };

        // console.log("order_data", order_data);

        // const OrderInfo = await get(query(ref(realtimeDb, "OrderExtraInfo")));

        // let extraInfo = OrderInfo.val();

        // extraInfo = {
        //   ...extraInfo,
        //   code: extraInfo?.code + 2,
        // };
        // const orderExtraRef = ref(realtimeDb, `OrderExtraInfo`);
        // await set(orderExtraRef, extraInfo);

        const orderRef = ref(realtimeDb, "orders");
        const newOrderRef = push(orderRef);
        const orderKey = newOrderRef.key;
        const orderDataWithKey = {
          ...order_data,
          key: orderKey,
        };
        await set(newOrderRef, orderDataWithKey);

        const cashOrderRef = ref(realtimeDb, `cash/${cashOrderId}`);
        await remove(cashOrderRef);
        // try {

        //   console.log(`Order with key ${cashOrderId} deleted successfully.`);
        // } catch (error) {
        //   console.error("Error deleting order: ", error);
        // }
        localStorage.removeItem("cart");
        setCart([]);
        setShowTooltip1(false);
        setShowModal1(false);
        setShowModal(false);
        navigate("/survey");
      }
    }
  };
  // console.log(cart, 'here>>>>>>>')
  return (
    <div className="cart-page">
      <div className="cart-page-header">
        <div className="cart-page-header-item">
          <a className="" onClick={() => navigate(-1)}>
            <img src={back} alt="" />
          </a>{" "}
          {t("view_your_order")}
        </div>
        <div className="cart-page-header-item">
          {t("amount_in_cart")}: {totalAmount}
        </div>
        <div className="cart-page-header-item">Scrollbar</div>
      </div>
      <div className="cart-page-order">
        <Table responsive>
          <thead>
            <tr>
              <th>{t("quantity")}</th>
              <th>{t("name")}</th>
              <th>{t("type")}</th>
              <th>{t("amount")}</th>
              <th>{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {cart.map((item) => (
              <tr key={item.key}>
                <td>{item.quantity}</td>
                <td>{item.name}</td>
                <td>{item.category === "DRINK" ? "Drinks" : "Ice cream"}</td>
                <td>{Number(item.price).toFixed(2)}</td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => handleIncreaseQuantity(item.key)}
                    className="custom-green-btn"
                  >
                    +
                  </Button>
                  {item.quantity === 1 ? (
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveItem(item.key)}
                      className="custom-red-btn"
                    >
                      x
                    </Button>
                  ) : (
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveItem(item.key)}
                      className="custom-red-btn"
                    >
                      -
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="cart-page-content">
        <div className="cart-table-code">
          <h4>{t("enter_table_code")}</h4>
          <form onSubmit={(e) => handleButtonClick(e, "")}>
            <OverlayTrigger
              show={showTooltip}
              placement="bottom"
              overlay={
                <Tooltip id="inputTooltip">
                  {t("please_enter_table_no")}
                </Tooltip>
              }
            >
              <input
                type="text"
                className="txtPost"
                placeholder={t("click_here")}
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  setShowTooltip(false);
                }}
                required
              />
            </OverlayTrigger>
          </form>
        </div>

        <div className="cart-table-payment">
          <div className="cart-table-payment-items">
            <Button onClick={(e) => handleButtonClick(e, "with_cards")}>
              {t("pay_easy_with_cards")}
            </Button>
            <p>{t("pay_desc")}</p>
          </div>
          <div className="cart-table-payment-items">
            <Button onClick={(e) => handleButtonClick(e, "call_staf")}>
              {t("call_staff_and_paycash")}
            </Button>
            <p>{t("call_staff_desc")}</p>
          </div>
          <div className="cart-table-payment-items">
            <Button onClick={(e) => handleButtonClick(e, "swish")}>
              {t("swish_payment")}
            </Button>
            <p>{t("swish_pay_desc")}</p>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("total_amount")}: {totalAmount}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("order_desc")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t("edit_order")}
          </Button>
          <Button variant="primary" onClick={() => setShowModal1(true)}>
            {t("done_staff")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal1} onHide={() => setShowModal1(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("enter_payment_password")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handlepaypassword}>
            <OverlayTrigger
              show={showTooltip1}
              placement="bottom"
              overlay={
                // <Tooltip id="inputTooltip">{t("enter_password")}</Tooltip>
                <Tooltip id="inputTooltip">{errormsg_tooltip}</Tooltip>
              }
            >
              <input
                type="password"
                className="txtPost"
                placeholder={t("enter_payment_password")}
                value={payment_password}
                required
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  setPayment_password(value);
                  setShowTooltip1(false);
                }}
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={10}
              />
            </OverlayTrigger>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowModal1(false)}>
            {t("cancel")}
          </Button>
          <Button variant="success" onClick={handlepaypassword}>
            {t("done")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Cart;
