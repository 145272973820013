import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore"
import { getDatabase } from "firebase/database"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  // main
  apiKey: "AIzaSyDy4-g3kX3yNXPNnncTp-MKHUtWSeW8o28",
  authDomain: "icecream-1254b.firebaseapp.com",
  databaseURL: "https://icecream-1254b.firebaseio.com",
  projectId: "icecream-1254b",
  storageBucket: "icecream-1254b.appspot.com",
  messagingSenderId: "946080140114",
  appId: "1:946080140114:web:e143266832378e3b8cc88b",
  measurementId: "G-BP0919HDW1"


  // test
  // apiKey: "AIzaSyC83Lvt5STOuGzZoBoMlJC7GyE6VqhY8pE",
  // authDomain: "glass-gard.firebaseapp.com",
  // projectId: "glass-gard",
  // storageBucket: "glass-gard.appspot.com",
  // messagingSenderId: "248754232336",
  // appId: "1:248754232336:web:ce39b2f688e9d9b2737e95",
  // measurementId: "G-BJ639TD4Z7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const realtimeDb =  getDatabase(app)
// const analytics = getAnalytics(app);
// const auth = getAuth(app)


export { db ,realtimeDb};


