import React, { useEffect, useState } from "react";
import "./style.css";
import swishlogo from "../assets/swish_logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useCart } from "../context/ CartContext";
import { realtimeDb } from "../firebase/firebase";
import {  onValue,  ref,  update } from "firebase/database";

function PaymentPage() {
  const navigate = useNavigate();
  const { setCart } = useCart();
  const { t } = useTranslation();
  const location = useLocation();
  const { order_id, amount } = location.state;
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [swishPaymentReference, setSwishPaymentReference] = useState(null);

  // console.log("loca", location.state);

  const [showTooltip, setShowTooltip] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  // const orderRef = ref(realtimeDb, `orders`);
  useEffect(() => {
    const orderRef = ref(realtimeDb, `orders`);

    const unsubscribe = onValue(orderRef, (snapshot) => {
      const orders = snapshot.val();
      if (orders) {
        Object.keys(orders).forEach(async (orderKey) => {
          const order = orders[orderKey];
          if (orderKey === order_id && paymentLoader === true) {
            if (order.paymentStatus === 1) {
              const now = new Date()
              const updatedData = {
                swishPaymentReference,
                updateTime: now.getTime()
              };
        
              const orderRe = ref(realtimeDb, `orders/${order_id}`);
              await update(orderRe, updatedData);
              // const order_data = await get(query(ref(realtimeDb, "order")));
              // const iceValue = order_data.val();
              // const new_compartment = {
              //   ...iceValue,
              //   swishPaymentReference: swishPaymentReference,
              //   updateTime: new Date(),
              // };

              // const itemRef = ref(realtimeDb, `order`);
              // await set(itemRef, new_compartment);

              setPaymentLoader(false);
              // toast.success("Payment Successful.", {
              //   toastId: "paymentSuccess",
              // });
              navigate(location.pathname, { replace: true, state: {} });
              localStorage.removeItem("cart");
              setCart([]);
              setTimeout(() => {
                navigate("/survey");
              }, 1000);
            } else if (order.paymentStatus === 2) {
              setPaymentLoader(false);
              navigate(location.pathname, { replace: true, state: {} });
              // toast.error("Payment Decline.", {
              //   toastId: "paymentDecline",
              // });
              setTimeout(() => {
                navigate("/payment-failed");
              }, 1000);
            } else if (order.paymentStatus === 3) {
              setPaymentLoader(false);
              navigate(location.pathname, { replace: true, state: {} });
              // toast.error("Payment Cancelled.", {
              //   toastId: "paymentCancelled",
              // });
              setTimeout(() => {
                navigate("/payment-failed");
              }, 1000);
            }

            console.log(
              `Order ${orderKey}, ${order_id} payment status: ${order.paymentStatus}, ${paymentLoader}`
            );
          }
        });
      } else {
        console.log("No orders available.");
      }
    });

    return () => unsubscribe(); // Cleanup the listener on component unmount
    // eslint-disable-next-line
  }, [order_id, paymentLoader, setCart, navigate, location.pathname]);

  const handlePayClick = async () => {
    console.log(mobileNumber, "0000000");
    if (mobileNumber.trim() === "") {
      toast.error(t("mobile_required"));
      setShowTooltip(true);
      return;
    }
    if (!amount || isNaN(amount) || amount <= 0) {
      toast.error("Invalid amount.");
      return;
    }
    if (!order_id) {
      toast.error("Order ID is required.");
      return;
    }

    let formattedMobileNumber;
    if (mobileNumber.startsWith("0")) {
      formattedMobileNumber = "46" + mobileNumber.substring(1);
    } else {
      formattedMobileNumber = mobileNumber;
    }
    // const formattedMobileNumber = mobileNumber.replace(/[^0-9]/g, '');

    // if (formattedMobileNumber.length < 10) {
    //   toast.error("Invalid mobile number.");
    //   return;
    // }

    try {
      const response = await axios.post(
        `https://swish.kallaglassgard.se/swish-client-v2/kalla/pay?phone_number=${formattedMobileNumber}&amount=${amount}&order_id=${order_id}`
      );
      console.log("Swish payment successful:", response.data);
      if (response.data?.success) {
        setPaymentLoader(true);
        setSwishPaymentReference(response?.data?.paymentReference);

        // setTimeout(() => {
        //   // toast.error("Payment Timeout.Please try Again.")

        // }, 20000);
      } else {
        toast.error(response?.data?.errorMessage);
      }
    } catch (err) {
      toast.error(t("payment_faild"));
      console.log("err", err);
    }
  };

  const goBack = () => {
    navigate("/cart");
  };

  return (
    <div className=" payment-container">
      {paymentLoader ? (
        <div className="container-wrapper shadow p-5">
          <div className="form-container">
            <h3>{t("dont_cancel_swish")}</h3>
          </div>

          <div className="image-container">
            <img src={swishlogo} alt="Swish Logo" className="swish-logo" />
          </div>
          <div className="image-container ">
            <p>{t("open_swishapp_accept")}</p>
          </div>
          <div className="image-container ">
            <Spinner
              style={{
                color: "pink",
                width: 50,
                height: 50,
              }}
            />
          </div>

          <div className="button-container">
            <button
              className="cancel-button"
              onClick={() => {
                setPaymentLoader(false);
              }}
            >
              {t("cancel_pay")}
            </button>
          </div>
        </div>
      ) : (
        <div className="container-wrapper shadow p-5">
          <div className="form-container">
            <h3>
              {t("pay_amount")}: {amount}:- SEK
            </h3>
            <div className="input-row">
              <label htmlFor="mobileNumber">{t("enter_mobile_no")}:</label>
              <form onSubmit={handlePayClick}>
                <OverlayTrigger
                  show={showTooltip}
                  placement="bottom"
                  overlay={
                    <Tooltip id="inputTooltip">
                      {t("placeholder_mobileNo")}
                    </Tooltip>
                  }
                >
                  <input
                    type="text"
                    className="txtPost"
                    placeholder={t("placeholder_mobileNo")}
                    value={mobileNumber}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setMobileNumber(value);
                      setShowTooltip(false);
                    }}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    required
                    maxLength={11}
                  />
                </OverlayTrigger>
              </form>
            </div>

            <div className="description">
              <label htmlFor="description">{t("swish_payment_msg")}</label>
            </div>
          </div>

          <div className="image-container">
            <img src={swishlogo} alt="Swish Logo" className="swish-logo" />
          </div>

          <div className="button-container">
            <button className="pay-button" onClick={handlePayClick}>
              {t("pay")}
            </button>
            <button className="cancel-button" onClick={goBack}>
              {t("cancel_pay")}
            </button>
          </div>
        </div>
      )}

      <div></div>
      <Toaster />
    </div>
  );
}

export default PaymentPage;
