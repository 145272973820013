import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFound from './pages/notFound';
import Home from './pages/home';
import IceCream from './pages/product/icecream';
import Drinks from './pages/product/drinks';
import Cart from './pages/product/cart';
import 'bootstrap/dist/css/bootstrap.min.css';
import PaymentPage from './swishpayment';
import Survey from './survey';
import ExampleComponent from './handle/ handlesubmit';
import Netspayment from './netspayment';
import SwishPaymentSuccessPage from './swishpayment/SwishPaymentSuccessPage';
import SwishPaymentFailed from './swishpayment/SwishPaymentFailed';
import PaymentSuccess from './netspayment/payment-success';
import PaymentFailed from './netspayment/payment-failed';




function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/ice_cream" element={<IceCream />} />
          <Route path="/drinks" element={<Drinks />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/swish" element={<PaymentPage />} />
          <Route path="/swish-payment-success" element={<SwishPaymentSuccessPage/>} />
          <Route path="/payment-failed" element={<SwishPaymentFailed/>} />

          <Route path="/survey" element={<Survey />} />
          <Route path="/nets" element={<Netspayment/>} />
          <Route path="/nets-payment-success" element={<PaymentSuccess/>} />
          <Route path="/nets-payment-failed" element={<PaymentFailed/>} />


          <Route path="/nets_payment" element={<ExampleComponent />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
