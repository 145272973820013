
import React, { useEffect, useState } from "react";
import { collection, getDocs, addDoc } from "@firebase/firestore";
import { db } from "../firebase/firebase";


function ExampleComponent() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "surveys")); 
        const itemsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log(itemsList,'00');

        setItems(itemsList);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchItems();
  }, []);

  return (
    <div>
      <h1>Firestore Items</h1>
      <ul>
        {items.map(item => (
          <li key={item.id}>{item.customerName}</li>
        ))}
      </ul>
    </div>
  );
}

export default ExampleComponent;




