/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./style.css";
import logo from "../assets/logo-white.png";
import back from "../assets/back.png";
import { useNavigate } from "react-router-dom";
import { ref, get, query } from "firebase/database";
import { realtimeDb } from "../firebase/firebase";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

function Survey() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [toggle, setToggle] = useState(false);
  // console.log(data, "question list");

  const nextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
    console.log(currentQuestion);
    if (currentQuestion === data.length - 1) {
      toast.success(t('thank_u'));
      setTimeout(() => {
        navigate("/");
      }, 2000);

    }
  };

  const No_thank = () => {
    toast.warn(t('canceled'))
    setTimeout(() => {
      navigate("/");
    }, 1000);
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const surveyRef = ref(realtimeDb, "survey");
        const surveyQuery = query(surveyRef);

        // console.log("Fetching data from Firebase...");

        const snapshot = await get(surveyQuery);

        setData(snapshot.val().questions);

        if (snapshot.exists()) {
          const dataArray = Object.entries(snapshot.val()).map(
            ([key, value]) => ({
              ...value,
              id: key,
            })
          );
          console.log(dataArray, "dataArray");
          //   setData(dataArray);
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (

    <div className="app-container">
      <div className="header">
        <div className="cart-page-header-item">
          <a className="" onClick={() => navigate("/")}>
            <img src={back} alt="" />
          </a>{" "}
          {t("survey")}
        </div>
        <img src={logo} alt="Logo" className="logo" />
      </div>
      {!toggle ? (
        <>
          <div className="content">
            <p>
              {t("survey_msg")}
            </p>
            <p>{t("survey_title")}</p>
          </div>
          <div className="button-container">
            <button className="yes-button" onClick={() => setToggle(true)}>
              {t("yes_please")}
            </button>
            <button className="no-button" onClick={No_thank}>{t("no_thanks")}</button>
          </div>
        </>
      ) : (
        <>
          {currentQuestion < data.length ? (
            <div className='question-content'>
              <h2>{t("quastion")} {currentQuestion + 1}</h2>
              <p>{data[currentQuestion].question}</p>
              <div className="answer-buttons">
                {data[currentQuestion].answers.map((answer, index) => (
                  <button key={index} className="answer-button" onClick={nextQuestion}>
                    {answer}
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <h2>{t("all_question_completed")}</h2>
            </div>
          )}
        </>
      )}


    </div>
  );
}

export default Survey;
