import React, { useEffect, useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";
import netslogo from "../assets/nets_logo.png";
import { get, push, query, ref, set, update } from "firebase/database";
import { realtimeDb } from "../firebase/firebase";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useCart } from "../context/ CartContext";

function PaymentSuccess() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const {  setCart } = useCart();
  const phpServerPath = "https://app.kallaglassgard.se";
  // const phpServerPath = "http://localhost/nets-card-payment";

  const [transaction, setTrasaction] = useState({
    amount: "",
    approvalcode: "",
    calcfee: "",
    cardtype: "",
    currency: "",
    dibsInternalIdentifier: "",
    fee: "",
    fullreply: "",
    key: "",
    lang: "",
    merchant: "",
    method: "",
    mobilelib: "web",
    orderKey: "",
    orderid: "",
    paytype: "",
    platform: "",
    status: "",
    test: "",
    textreply: "",
    theme: "",
    transact: "",
  });

  const handleTransaction = async (id) => {
    setLoader(true);
    const querySnapshot = await get(query(ref(realtimeDb, "paymentInfo")));
    let data;
    if (querySnapshot.exists()) {
      data = querySnapshot.val();
      console.log("iru>>", data);
    } else {
      console.log("Snapshot exists but no matching data found.");
    }

    const res = await axios.get(
      `${phpServerPath}/create-payment.php?paymentid=${id}`
    );
    console.log("res", res);
    // const order_id = localStorage.getItem("netOrderId") || "";
    const order_id = res.data?.payment?.orderDetails?.reference;

    setTrasaction({
      ...transaction,
      ...data,
    });

    console.log("new", {
      ...transaction,
      ...data,
      ...res.data?.payment.orderDetails,
      cardtype: res.data.payment.paymentDetails?.paymentMethod,
      merchantId: data?.merchant,
      paytype: data?.payTypes.join(","),
      orderKey: order_id,
    });
    const transaction_data = {
      ...transaction,
      ...data,
      ...res.data?.payment.orderDetails,
      cardtype: res.data.payment.paymentDetails?.paymentMethod,
      merchant: data?.merchantId,
      paytype: data?.payTypes.join(","),
      orderKey: order_id,
    };

    delete transaction_data.merchantId;
    delete transaction_data.payTypes;
    delete transaction_data.accepturl;

    const updatedData = {
      paymentStatus: 1,
    };

    const orderRe = ref(realtimeDb, `orders/${order_id}`);
    await update(orderRe, updatedData);

    const itemRef = ref(realtimeDb, `transactions`);
    const newTransaction = push(itemRef);
    const transKey = newTransaction.key;
    const transactionWithKey = {
      ...transaction_data,
      key: transKey,
    };
    await set(newTransaction, transactionWithKey);
    console.log("all updated successfully.")
    localStorage.removeItem("cart");
    setCart([]);
    setLoader(false);
  };
  useEffect(() => {
    const paymentid = window.location.search.split("=")[1];
    console.log("paymentid", paymentid);
    if (paymentid) {
      handleTransaction(paymentid);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div className=" payment-container">
      <div className="container-wrapper shadow p-5">
        <div className="form-container">
          <h3>{t("swish_payment_successfully")}</h3>
        </div>

        <div className="image-container">
          <img src={netslogo} alt="Nets Logo" className="swish-logo" />
        </div>
        <div className="image-container ">
          {/* <p>{t('open_swishapp_accept')}</p> */}
        </div>
        <div className="image-container ">
          {/* <Spinner
              style={{
                color: "pink",
                width:50,
                height:50
              }}
            /> */}
        </div>

        <div className="button-container">
          {loader ? (
            <Spinner />
          ) : (
            <button
              className="cancel-button"
              onClick={() => {
                navigate("/survey");
              }}
            >
              {t("swish_payment_failed_button")}
            </button>
          )}
        </div>
      </div>

      <div></div>
      <Toaster />
    </div>
  );
}

export default PaymentSuccess;
