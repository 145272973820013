import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import icecream from "../../assets/ice-cream.png";
import drinks from "../../assets/drinks.png";
import globe from "../../assets/globe.png";
import { Modal, Button } from "react-bootstrap";
import { useState } from "react";

function Sidebar() {

  const { t, i18n } = useTranslation();
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'de', name: 'German' },
    { code: 'swe', name: 'Swedish' },
  ];

  const [show, setShow] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); 

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language.code);
    i18n.changeLanguage(language.code);
  };

  return (
    <div className="sidebar-menu">
      <ul>
        <li>
          <NavLink
            to="/ice_cream"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <img src={icecream} alt="Ice Cream" />
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/drinks"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <img src={drinks} alt="Drinks" />
          </NavLink>
        </li>
        <li>
          <span onClick={handleShow} role="presentation">
            <img src={globe} alt="Change Language" />
          </span>
        </li>
      </ul>

      <Modal show={show} onHide={handleClose} centered className="language-modal" backdrop="static">
        <Modal.Header>
          <Modal.Title>{t('welcome')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="language">
            <ul>
              {languages.map((language) => (
                <div
                  key={language.code}
                  className={`language-item ${selectedLanguage === language.code ? 'active' : ''}`}
                >
                  <input
                    type="radio"
                    aria-label={`radio ${language.name}`}
                    name="lang"
                    id={language.code}
                    checked={selectedLanguage === language.code}
                    onChange={() => handleLanguageSelect(language)}
                  />{' '}
                  <label htmlFor={language.code}>{language.name}</label>
                </div>
              ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
          >
            {t('ok')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Sidebar;
