import React, { useEffect } from "react";
import "./style.css";
import logo from "../assets/logo-ellips.png";
import back from "../assets/ic_close.png";
import netslogo from "../assets/nets_logo.png";

import { Link, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { get, query, ref } from "firebase/database";
import { realtimeDb } from "../firebase/firebase";
import axios from "axios";

function Netspayment() {
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { id } = location?.state;
  const phpServerPath = "https://app.kallaglassgard.se";
  const clientUrl = "https://app.kallaglassgard.se";
  // const phpServerPath = "http://localhost/nets-card-payment";
  // const clientUrl = "http://localhost:3002";

  const handleCardPayment = async () => {
    const items = JSON.parse(localStorage.getItem("cart"));

    const querySnapshot = await get(query(ref(realtimeDb, "paymentInfo")));
    let data;
    if (querySnapshot.exists()) {
      data = querySnapshot.val();
      console.log("iru>>", data);
    } else {
      console.log("Snapshot exists but no matching data found.");
    }

    const new_items = items?.map((item) => ({
      reference: item.key,
      name: item.name,
      quantity: item.quantity,
      unit: "pcs",
      unitPrice: item.basePrice,
      grossTotalAmount: item.price * 100,
      netTotalAmount: item.price * 100,
    }));
    console.log("new_items", new_items);

    let amount = new_items?.reduce(
      (total, item) => total + item?.netTotalAmount,
      0
    );
    console.log("amount", amount);
    const order = {
      items: new_items,
      amount: amount,
      currency: data?.currencyCode,
      reference: id,
    };
    console.log("order", order);

    const payload = {
      checkout: {
        integrationType: "HostedPaymentPage",
        returnUrl: `${clientUrl}/nets-payment-success`,
        termsUrl: `string`,
        charge: true,
        publicDevice: true,
        merchantHandlesConsumerData: true,
        countryCode: "SWE",
        appearance: {
          displayOptions: {
            showMerchantName: true,
            showOrderSummary: true,
          },
          textOptions: {
            completePaymentButtonText: "pay",
          },
        },
      },
      order,
      lang: data?.language,
    };

    try {
      const response = await axios.post(
        `${phpServerPath}/create-payment.php`,
        payload
      );

      console.log("res", response);
      let lang = "sv-SE";
      // if (payload?.lang === "en") {
      //   lang = "en-Eg";
      // } else if (payload?.lang === "de") {
      //   lang = "de-DE";
      // }
      console.log(response);
      const url = `${response?.data?.hostedPaymentPageUrl}&language=${lang}`;
      window.location.href = url;
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    handleCardPayment();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="app-container">
      <div className="header">
        <div className="cart-page-header-item">
          <Link to="/cart">
            <img src={back} alt="" />
          </Link>{" "}
        </div>
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="question-content">
        <img src={netslogo} alt="Logo" className="logo-net" />
        <div style={{ marginTop: "20px" }}>
          <Spinner style={{ color: "#800000" }} />
        </div>
      </div>
    </div>
  );
}

export default Netspayment;
